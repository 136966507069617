<!-- box is a special component where we can't delegate it to another
Angular component (e.g. mesop-box) because it will create an intermediate DOM node
which interferes with Flexbox styling which requires children (direct descendants)
to have certain styling properties -->

<ng-container *ngIf="isBoxType() || type() == null">
  <ng-container
    *ngFor="let child of component.getChildrenList(); trackBy: trackByFn"
  >
    <component-renderer [component]="child"> </component-renderer>
  </ng-container>
</ng-container>

<!-- This DOM node is a hack to get a reference to the ViewContainerRef; need
to *not* render it, otherwise it causes weird layout issues (e.g. flexbox) -->
<span style="display: none" [attr.data-key]="getKey()" #insertion></span>

<ng-template #childrenTemplate>
  <ng-container
    *ngFor="let child of component.getChildrenList(); trackBy: trackByFn"
  >
    <component-renderer [component]="child"> </component-renderer>
  </ng-container>
</ng-template>

<!-- Editor overlay -->
<ng-template #editorOverlay>
  <div class="editor-overlay">
    {{getComponentName()}}
    <span class="editor-icons">
      <mat-icon
        matTooltip="Add sibling component"
        matTooltipShowDelay="700"
        class="add-component"
        (click)="addSiblingComponent()"
        >add</mat-icon
      >
      <mat-icon
        *ngIf="canAddChildComponent()"
        matTooltip="Add child component"
        matTooltipShowDelay="700"
        class="add-component"
        (click)="addChildComponent()"
        >new_window</mat-icon
      >
    </span>
  </div>
</ng-template>
